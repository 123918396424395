.link {
  cursor: pointer;
  color: var(--marketplaceColor);
  margin: 0;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.separator {
  margin: 0 5px;
  font-size: 12px;
}

.actionLinks {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sliderWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sliderBarWrapper {
  width: 100%;
}
.sliderBarWrapper span {
  color: #ff627c !important;
}

.modalContent svg {
  color: #ff627c !important;
}
