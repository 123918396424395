.customIcon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
}

.qrWrapper {
  cursor: pointer;
}
