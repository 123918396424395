.share {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
  box-shadow: var(--boxShadow);
  padding: 15px;
  border-radius: 30px;
  /* height: 50px; */
  &:hover {
    transform: scale(1.03);
  }
}

.icon {
  transform: scale(1.2);
}

.shareButtonsWrapper {
  display: none;
}
.share:hover .shareButtonsWrapper {
  display: flex;

  background-color: white;
  box-shadow: var(--boxShadowButton);

  padding: 5px;
  border-radius: 3px;
  position: absolute;
  bottom: 5px;
  right: 0;
  z-index: 999;
  transform: translateY(100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shareButtonsWrapper button {
  margin: 0 5px;

  &:hover {
    transform: scale(1.05);
  }
}

.shareText {
  max-height: 2pc;
  margin-right: 1pc;
  margin: auto 0;
}

.customIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customIcon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
}
